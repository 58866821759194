import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textStepperDailyExercise', 'physicalActivities'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextCustom = makeShortcode("TextCustom");
const TextStepper = makeShortcode("TextStepper");
const Grid = makeShortcode("Grid");
const SaveTextField = makeShortcode("SaveTextField");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "encourage-daily-exercise"
    }}>{`Encourage daily exercise`}</h1>
    <TextCustom customColor="blue" variant="h4" paragraph mdxType="TextCustom">
  But how do I get my teenager to do 60 minutes of exercise everyday?
    </TextCustom>
    <h3 {...{
      "id": "build-it-into-everyday-life"
    }}>{`Build it into everyday life.`}</h3>
    <p>{`Check what your teenager might already do during the day and add other activities to their day to bring the total to 60 minutes.`}</p>
    <TextStepper id="textStepperDailyExercise" sequenceSpeed={1200} fadeSpeed={1200} variant="h6" list={[{
      text: 'Dancing.'
    }, {
      text: 'Walking to/from school.'
    }, {
      text: 'Swimming.'
    }, {
      text: 'Tennis.'
    }, {
      text: 'Bike riding.'
    }, {
      text: 'Walking the dog.'
    }, {
      text: 'Jogging.'
    }, {
      text: 'Aerobics.'
    }, {
      text: 'Netball.'
    }, {
      text: 'Basketball.'
    }, {
      text: 'Soccer.'
    }, {
      text: 'Rugby.'
    }, {
      text: 'Martial arts.'
    }, {
      text: 'Take the stairs instead of the lift or escalator.'
    }, {
      text: 'Bike riding to/from school.'
    }, {
      long: true,
      text: 'Instead of travelling by car for short distances, you could walk, ride or skateboard.'
    }, {
      long: true,
      text: 'Get off the bus one stop earlier and walk the rest of the way.'
    }, {
      text: 'Skipping.'
    }, {
      text: 'Playing footy with friends.'
    }, {
      text: 'Cricket.'
    }, {
      text: 'Meet friends for a walk instead of going to a movie.'
    }, {
      text: 'Throw a frisbee with a friend.'
    }, {
      text: 'Yoga.'
    }, {
      text: 'Athletics.'
    }, {
      text: 'Golf.'
    }, {
      text: 'Gymnastics.'
    }, {
      text: 'Hockey.'
    }, {
      text: 'Volleyball.'
    }, {
      text: 'Indoor rock-climbing.'
    }, {
      text: 'Self-defence classes.'
    }, {
      text: 'Table tennis.'
    }, {
      text: 'Skateboarding.'
    }]} mdxType="TextStepper" />
    <h3 {...{
      "id": "make-it-fun"
    }}>{`Make it fun!`}</h3>
    <p>{`Remember, your teenager is more likely to continue an activity if they enjoy it, so let them choose what they do.`}</p>
    <h3 {...{
      "id": "do-it-yourself"
    }}>{`Do it yourself.`}</h3>
    <p>{`You can help your teenager become more active by doing it yourself. You might even be able to do some things together!`}</p>
    <TextCustom customColor="blue" variant="h4" mdxType="TextCustom">
  Activity
    </TextCustom>
    <p>{`What physical activity am I going to encourage my teenager to do more of this week? How will I approach this with them?`}</p>
    <Grid container justify="center" spacing={2} mdxType="Grid">
  <SaveTextField id="physicalActivities" variant="outlined" fullWidth={true} multiline={true} label="Insert text here" mdxType="SaveTextField" />
    </Grid>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      